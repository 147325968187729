import { Socket } from 'socket.io-client';
import { IFaGrantNotification } from '../interfaces/redux/IFaGrantNotification';

export enum SocketActionsEnum {
  FA_GRANT_NOTIFICATION = 'FA_GRANT_NOTIFICATION',
  UPDATE_PATIENT_TASKS_MULTIUSER = 'UPDATE_PATIENT_TASKS_MULTIUSER',
}

export type FaGrantNotificationActionPayload = {
  socket: Socket;
  notification: IFaGrantNotification;
  forceTaskType: 'FA';
};

export interface IAction {
  type: SocketActionsEnum;
  payload: FaGrantNotificationActionPayload | any;
}

export function handleFaGrantNotification(payload: FaGrantNotificationActionPayload): IAction {
  return {
    type: SocketActionsEnum.FA_GRANT_NOTIFICATION,
    payload,
  };
}

export function updatePatientTaskMultiUser(patientWithTasks: any) {
  return {
    type: SocketActionsEnum.UPDATE_PATIENT_TASKS_MULTIUSER,
    payload: patientWithTasks,
  };
}
