import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { compose } from 'recompose';
import { styles } from '../styles/auth-page.styles';
import Paragraph from '../components/paragraph';
import Button from '../components/button';

const ContinuePage = ({ classes }) => {
  const local_response_handler = async () => {
    window.parent.postMessage(
      { status: 'dc_questionnaire_completed', url: window.location.href },
      window.location.origin,
    );
  };

  return (
    <Box className={classes.container}>
      <Grid container spacing={3.5}>
        <Grid container item xs={12}>
          <Grid item xs={12} data-qa-id="title">
            <Paragraph type="title">
              An unknown error has occured.
              <br />
              Click continue completing your order.
            </Paragraph>
          </Grid>
          <Grid item xs={12}>
            <Box my={0} component="hr" width={250} className={classes.divider} />
          </Grid>
        </Grid>
      </Grid>
      <Box textAlign="center">
        <Grid container item xs={12} spacing={6}>
          <Grid item xs={12}>
            <Button
              className={classes.button}
              onClick={local_response_handler}
              data-qa-id="continue-order"
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default compose(withStyles(styles))(ContinuePage);
