import React, { useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { TextField } from '@mui/material';
import { isValidDate } from 'utils/date-utils';

const DobInput = ({ error, classes, updateDob, dobValue }) => {
  const { dobInputError, dobInput } = classes;
  const [localValue, setLocalValue] = useState(dobValue);

  const handleKeyDown = e => {
    const newValue = localValue + e.key;
    const rawValue = newValue.replace(/[^0-9]/g, '');
    const month = parseInt(rawValue.slice(0, 2), 10);
    const day = parseInt(rawValue.slice(2, 4), 10);
    const year = parseInt(rawValue.slice(4, 8), 10);

    const isMonthComplete = rawValue.length >= 2;
    const isDayComplete = rawValue.length >= 4;
    const isComplete = rawValue.length === 8;

    if (
      !isValidDate(month, day, year, isMonthComplete, isDayComplete, isComplete) &&
      e.key.match(/[0-9]/)
    ) {
      e.preventDefault();
    }
  };

  const handleChange = e => {
    setLocalValue(e.target.value);
    updateDob(e.target.value);
  };

  return (
    <PatternFormat
      customInput={TextField}
      value={localValue}
      autoComplete="off"
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      format="##-##-####"
      mask={['m', 'm', 'd', 'd', 'y', 'y', 'y', 'y']}
      placeholder="mm-dd-yyyy"
      className={error ? dobInputError : dobInput}
      disabled={error}
      spellCheck="false"
      data-qa-id="birthday"
    />
  );
};

export default DobInput;
