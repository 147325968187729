import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import { RemoveRedEye } from '@mui/icons-material';
import { useSelector } from 'react-redux';

export const MultiUserTaskIndicator = props => {
  const { taskId } = props;
  const [selectedUserCustomList] = useState(window.USER.id);
  const userWorkingList = useSelector(state => state.socketReducer.usersWorkingTasks);

  const userWithTasks = userWorkingList.map(x => ({
    id: x.id,
    name: x.name,
    tasks: x.patient?.flatMap(t => t.tasks),
  }));
  const list = userWithTasks.filter(
    x => x.tasks.some(t => t === taskId) && x.id !== selectedUserCustomList,
  );
  const redEye = hasItems =>
    hasItems ? (
      <RemoveRedEye
        style={{
          fill: '#c78a80',
          fontSize: '20px',
          marginRight: 6,
          transform: 'translateY(-1px)',
        }}
      />
    ) : (
      <RemoveRedEye style={{ fill: '#70777a44', fontSize: '20px', marginRight: 6 }} />
    );
  return (
    <Tooltip
      title={list.map(item => (
        <div key={`user-${item.id}`}>{item.name}</div>
      ))}
    >
      <div
        style={{
          width: '26px',
          height: '20px',
        }}
      >
        {redEye(list.length > 0)}
      </div>
    </Tooltip>
  );
};
