import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import HTTP from 'services/http';
import uuidV4 from 'uuid/v4';
import {
  gaQuestionnairePatientView,
  gaQuestionnairePatientSubmit,
  gaQuestionnairePatientAnswerQuestion,
} from 'actions/action-questionnaires';
import { initPatientGA } from 'services/utils/analytics';
import InitialPage from './pages/initial-page';
import AuthPage from './pages/auth_page';
import QuestionsPage from './pages/questions-page';
import FinalPage from './pages/final-page';
import InvalidPage from './pages/invalid-page';
import SubmittedPage from './pages/submitted-page';
import LoadingPage from './pages/loading-page';
import ErrorPage from './pages/error-page';
import NextPage from './pages/next-page';
import ContinueWithOrderPage from './pages/continue-with-order-page';
import PAGE_INDEX from './pages/page_index';

const generateQuestionnaireAnswers = (questionnaire, partialAnswers, isSubmitting = false) => {
  const payload = {
    isQuestionnaireSubmitted: isSubmitting,
    questionnaireAnswers: {},
  };

  Object.keys(partialAnswers).forEach(answerKey => {
    Object.keys(questionnaire).forEach(questionnaireKey => {
      if (questionnaire[questionnaireKey].id === parseInt(answerKey, 10)) {
        payload.questionnaireAnswers[questionnaireKey] = {
          answers: (partialAnswers[answerKey] || []).map(a => (a === null ? null : String(a))),
        };
      }
    });
  });

  return payload;
};

const INITIAL_PAGE = PAGE_INDEX.INITIAL_PAGE;
const QUESTIONS_PAGE = PAGE_INDEX.QUESTIONS_PAGE;
const FINAL_PAGE = PAGE_INDEX.FINAL_PAGE;
const SUBMITTED_PAGE = PAGE_INDEX.SUBMITTED_PAGE;
const INVALID_PAGE = PAGE_INDEX.INVALID_PAGE;
const NEXT_PAGE = PAGE_INDEX.NEXT_PAGE;
const CONTINUE_WITH_ORDER_PAGE = PAGE_INDEX.CONTINUE_WITH_ORDER_PAGE;
const AUTH_PAGE = PAGE_INDEX.AUTH_PAGE;

export default () => {
  const [page, setPage] = useState(AUTH_PAGE);
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(null);
  const [patientDob, setPatientDob] = useState(null);
  const [dobIsVerified, setDobIsVerified] = useState(false);
  const { questionnaireLink } = useParams();
  const [questionnaire, setQuestionnaire] = useState(null);
  const [answer, setAnswer] = useState(null);
  const [patientId, setPatientId] = useState(null);

  const dispatch = useDispatch();

  const onError = useCallback((message, ex) => {
    const uuid = uuidV4().substr(0, 6);
    if (error && error.message) {
      // eslint-disable-next-line no-console
      console.error(`${uuid} - ${ex.message} - ${ex.stack}`);
    } else {
      // eslint-disable-next-line no-console
      console.error(`${uuid} - ${ex}`);
    }
    setError({
      message,
      uuid,
    });
  }, []);

  const setQuestionnaireFromData = data => {
    if (data) {
      setPatientId(data.patientId);
      initPatientGA(data.patientId);
      const answers = data.answers || {};
      const initialAnswers = Object.values(data.questionnaire).reduce((acc, q) => {
        acc[q.id] = new Array(q.questions.length).fill(null);
        return acc;
      }, {});
      Object.keys(answers).forEach(k => {
        if (data.questionnaire[k]) {
          initialAnswers[data.questionnaire[k].id] = JSON.parse(answers[k]) || [];
        }
      });
      setQuestionnaire(data.questionnaire);
      setAnswer(initialAnswers);
    }
    if (!data.isValid) {
      setPage(INVALID_PAGE);
    } else if (Object.values(data.questionnaire).length === 0) {
      setPage(SUBMITTED_PAGE);
    }
  };

  useEffect(() => {
    if (!dobIsVerified) {
      return;
    }

    HTTP.post(`/public/questionnaires/${questionnaireLink}`, { dob_provided: patientDob })
      .then(response => {
        try {
          const { data } = response;
          setQuestionnaireFromData(data);
        } catch (e) {
          if (window.self !== window.top) {
            setPage(CONTINUE_WITH_ORDER_PAGE);
            return null;
          }
          onError('Failed to load the questionnaire', e);
          return;
        } finally {
          setReady(true);
        }
      })
      .catch(ex => {
        if (window.self !== window.top) {
          setPage(CONTINUE_WITH_ORDER_PAGE);
          return;
        }
        onError('The questionnaire was not found', ex);
      });
  }, [questionnaireLink, patientId, onError, dobIsVerified]);

  if (page === CONTINUE_WITH_ORDER_PAGE) {
    return <ContinueWithOrderPage />;
  }

  if (error) {
    return <ErrorPage message={error.message} uuid={error.uuid} />;
  }

  if (!dobIsVerified) {
    return (
      <AuthPage
        setPage={setPage}
        setReady={setReady}
        setDobIsVerified={setDobIsVerified}
        setPatientDob={setPatientDob}
        questionnaireLink={questionnaireLink}
        onError={onError}
        setQuestionnaireFromData={setQuestionnaireFromData}
      />
    );
  }

  if (!ready) {
    return <LoadingPage />;
  }
  if (!questionnaire) {
    return 'Error loading questions.';
  }
  const store = {
    questionnaire,
    answer,
    questionnaireLink,
    generateQuestionnaireAnswers,
    patientDob,
  };

  return (
    <>
      {page === INITIAL_PAGE && (
        <InitialPage
          onNext={() => {
            setPage(QUESTIONS_PAGE);
            dispatch(gaQuestionnairePatientView(questionnaireLink, patientId));
          }}
          store={store}
        />
      )}
      {page === QUESTIONS_PAGE && (
        <QuestionsPage
          onBack={() => setPage(INITIAL_PAGE)}
          onNext={() => {
            setPage(FINAL_PAGE);
            dispatch(gaQuestionnairePatientSubmit(questionnaireLink, patientId));
          }}
          onQuestionnaireChange={newQuestionnaireData => {
            setPage(NEXT_PAGE);
            setQuestionnaireFromData(newQuestionnaireData);
          }}
          onLoading={setLoading}
          onError={onError}
          store={store}
          dispatchPatientAnswerQuestion={questionNumber => {
            dispatch(
              gaQuestionnairePatientAnswerQuestion(questionnaireLink, patientId, questionNumber),
            );
          }}
          setPage={setPage}
        />
      )}
      {page === FINAL_PAGE && <FinalPage />}
      {page === SUBMITTED_PAGE && <SubmittedPage />}
      {page === INVALID_PAGE && <InvalidPage />}
      {page === NEXT_PAGE && (
        <NextPage
          onNext={() => {
            setPage(QUESTIONS_PAGE);
          }}
        />
      )}
      {loading && <LoadingPage />}
    </>
  );
};
