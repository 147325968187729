const PAGE_INDEX = {
  INITIAL_PAGE: 0,
  QUESTIONS_PAGE: 1,
  FINAL_PAGE: 2,
  SUBMITTED_PAGE: 3,
  INVALID_PAGE: 4,
  NEXT_PAGE: 5,
  CONTINUE_WITH_ORDER_PAGE: 6,
  AUTH_PAGE: 7,
};

export default PAGE_INDEX;
